const { Icon } = require("dyl-components");
const { PAYMENT_NETWORK_ICONS } = require("shared/constants/PAYMENT_NETWORK_ICONS");

const PaymentInfo = ({ info }) => {
    const { payment_type: type, payment_account } = info;
    if (type === "eCheck") {
        return (
            <div>
                <Icon className={`fas fa-money-check-dollar`} />{" "}
                {payment_account.replace("XXXX", "****")}
            </div>
        );
    }
    if (type === "credit_card") {
        return (
            <div>
                <Icon className={PAYMENT_NETWORK_ICONS[info.payment_network?.toLowerCase()]} />{" "}
                {payment_account.replace("XXXX", "****")} <i>{info?.is_primary ? " (Primary)": ""}</i>
            </div>
        );
    }
    return null;
};

export default PaymentInfo;