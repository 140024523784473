import { Button } from "dyl-components";
import React, { useContext } from "react";

import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const AddQuote = ({ isOpportunity }) => {
    const { onOpenAddProducts } = useContext(QuoteBuilderContext);

    return (
        <div>
            <Button
                type="button"
                onClick={() => {
                    onOpenAddProducts();
                }}
                fluid
            >
                {isOpportunity ? "Add Quote" : "Product Catalog"}
            </Button>
        </div>
    );
};

export default AddQuote;
