import React, { useEffect } from 'react';
import { Button, Modal, Notification, STATUS_TYPES, generateResolver, yup, TemperatureIcon, PriorityIcon } from 'dyl-components';
import { Form, Header, List, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomGroupUtils, PhoneUtil, ValidationUtils } from 'utils';

import { Controller, useFieldArray, useForm } from 'react-hook-form';

import './AddContactModal.scss';
import contactsActions from 'actions/contacts';
import customFieldsGroupActions from "actions/custom_fields_group";
import AccountOptions from 'shared/forms/AccountOptions';
import accountActions from 'actions/account';
import pipelineActions from 'actions/pipeline';
import ContactOptions from 'shared/forms/ContactOptions';
import { useNavigate } from 'react-router-dom';
import MasterSecondarySourceOptions from 'shared/forms/MasterSecondarySourceOptions';
import campaignsAction from "actions/campaigns";
import CustomData from 'shared/CustomData';

import sequencesActions from 'actions/sequences';
import contactSequenceActions from 'actions/contact_sequence';
import sequenceTasksActions from 'actions/sequence_tasks';
import PHONE_TYPE_OPTIONS from 'shared/constants/PHONE_TYPE_OPTIONS';
import EMAIL_TYPE_OPTIONS from 'shared/constants/EMAIL_TYPE_OPTIONS';

const ModalContent = ({
    onClose,
    onDirtyChange
}) => {
    const { group, isCreating, isReadingCategories, isReadingCustomFields, pipelineCategories, fields, campaignConvertedOptions } = useSelector(state => {
        const { isCreating: isCreatingContacts } = state.contacts;
        const custom_groups = (state.custom_fields_group.modal_standard_group.children || []).filter(group => !group.standard)
        const fields = state.custom_fields_group.modal_standard_group?.fields?.data || [];
        return {
            isCreating: isCreatingContacts || state.pipeline.isConvertingContact || state.account.isLinkingContactsToAccount || state.contact_sequence.isHandlingConvert || state.sequences.isAddingContactToSequence || state.sequence_tasks.isReadingSequenceContactTasks,
            isReadingCustomFields: state.custom_fields_group.isReadingContactStandardFieldGroup || state.custom_fields_group.isReadingModalStandardFieldGroup,
            group: custom_groups,

            pipelineCategories: state.pipeline.categories.map(category => ({
                key: category.id,
                value: category.id,
                text: category.name,
                stages: category.stages?.map(stage => ({
                    key: stage.id,
                    value: stage.id,
                    text: stage.name,
                    sequence_id: stage.sequence_id
                })) || []
            })),
            isReadingCategories: state.pipeline.isReadingCategories,
            campaignConvertedOptions: state.campaigns.campaignConvertedOptions,
            fields
        };
    });

    const dispatch = useDispatch();

    const custom_groups = CustomGroupUtils.formatFields(group);

    const { formState: { isDirty, isValid, errors }, control, trigger, setValue, handleSubmit, watch, clearErrors, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            pipeline: null,
            stage: null,
            type: null,
            close_probability: '',
            master_source: null,
            secondary_source: null,

            product_interests: [],
            expected_revenue: '',
            priority: null,
            rating: null,
            score: null,
            acquisition_cost: null,
            additional_cost: null,
            campaign_converted: null,

            contactsToConvert: [{
                first_name: '',
                last_name: '',
                suffix: '',
                birthday: '',
                phones: [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }],
                emails: [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }],
                website: '',
                interests: [],
                department: '',
                job_title: '',

                address_label: 'Business',
                street: '',
                apartmentUnitOrFloor: '',
                city: '',
                state: '',
                zip: '',

                account_id: null,

                business_name: '',
                industry_sector: null,
                sub_industry: null,
                employee_size: 0,
                annual_revenue: '',
                products_sold: '',

                household_name: '',
                household_type: null,
                household_members: 0,
                number_of_children: 0,
                annual_household_income: '',

                group: []
            }],

            account: null,
            children: custom_groups
        },
        resolver: generateResolver({
            pipeline: yup.string().required('This field is required'),
            stage: yup.string().required('This field is required'),

            contactsToConvert: yup.array().of(yup.object().shape(ValidationUtils.NEW_CONTACT_VALIDATIONS, ['emails', 'phones'])),
            children: CustomGroupUtils.generateValidationSchema(custom_groups)
        })
    });

    const [watchedContacts, watchedPipeline] = watch(['contactsToConvert', 'pipeline']);

    const { fields: contactsToConvert, update: updateContact } = useFieldArray({
        control,
        name: 'contactsToConvert'
    });

    const onReadCampaignConverted = (contact_id) => {
        return dispatch(campaignsAction.readPersonCampaignConverted({ person_id: contact_id, campaign_conversion: "lead" }));
    };

    const onSelectContact = (contact, index) => {
        const emails = contact.email?.email ? [{ value: contact.email.email, type: contact.email.email_type }] : [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }];
        const phones = contact.phone?.phone ? [{ value: contact.phone.phone, type: contact.phone.phone_type }] : [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }];
        updateContact(index, {
            ...watchedContacts[index],
            contact_id: contact.id,
            first_name: contact.first_name || '',
            last_name: contact.last_name || '',
            new: contact.id ? false : contact.new,
            account_id: contact?.account ? contact?.account?.id || undefined : null,
            phones,
            emails
        });
        setValue('account', contact?.account?.id ? { name: contact.account.name, id: contact.account.id, isAlreadyLinked: true } : null);
        if (contact.contact_id) {
            onReadCampaignConverted({ person_id: contact.contact_id });
        } else {
            setValue('campaign_converted', null)
        }
    }

    const navigate = useNavigate();

    const onCreate = async (data) => {
        const custom_data = {
            fields: CustomGroupUtils.extractLeadDetails(data),
            children: CustomGroupUtils.groupAndFlatten({ children: data.children }).children
        };

        const { stage, master_source, secondary_source, campaign_converted, pipeline } = data;

        const contacts = data.contactsToConvert;

        try {
            const category = pipelineCategories.find(category => category.value === Number(pipeline));
            const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(stage));
            const idOfContactWithNewModule = await (async () => {
                const isNewContact = contacts[0].contact_id === undefined;
                if (isNewContact) {
                    const newContacts = await dispatch(contactsActions.createContact(contacts.map(contact => ({
                        custom_data: {
                            fields: {
                                department: contact.department
                            },
                            children: {
                                ...CustomGroupUtils.extractBusinessAndHouseholdDetails(contact)
                            }
                        },
                        date_of_birth: contact.birthday,
                        email: contact.emails.filter(emailItem => emailItem.value).map(({ value: email, main, type }) => ({
                            main,
                            email,
                            email_type: type
                        })),
                        first_name: contact.first_name,
                        last_name: contact.last_name,
                        suffix: contact.suffix,
                        influence: "influencer",
                        location: [
                            {
                                street: contact.street,
                                additional_street: contact.apartmentUnitOrFloor,
                                city: contact.city,
                                state: contact.state,
                                zip: contact.zip,
                                label: contact.address_label
                            }
                        ],
                        phone: contact.phones.filter((phone) => phone.value).map(({ value, main, type }) => ({
                            main,
                            phone: PhoneUtil.getUnformatted(value),
                            phone_type: type
                        })),
                        job_title: contact.job_title,
                        website: contact.website,
                        module: {
                            pipeline_stage_id: Number(stage),
                            custom_data,
                            master_source,
                            secondary_source_id: secondary_source
                        }
                    })), { account_id: data.account?.id }));
                    const contact_id = newContacts[0];
                    if (newStage?.sequence_id) {
                        await dispatch(sequencesActions.addToSequence({ contact_id }, null, newStage?.sequence_id));
                    }
                    return contact_id;
                }
                if (data.account?.id && data.account?.id !== contacts[0].account_id) {
                    await dispatch(accountActions.linkContacts(data.account?.id, contacts.map(contact => ({
                        person_id: contact.contact_id,
                        primary_person: false,
                        old_account_id: data.account?.id
                    }))));
                }
                const contact_id = contacts[0].contact_id;
                const { workflow_id, tasks } = await dispatch(sequenceTasksActions.readContactTasks(contact_id));
                const areSequenceTasksCompleted = tasks?.length === 0 || tasks?.every(task => task.status === 'complete' || task.status === 'canceled') || true;
                const canApplyNewSequence = workflow_id ? await dispatch(contactSequenceActions.handleConvert(contact_id)) : true;
                await dispatch(pipelineActions.convertContact(contacts.map(contact => ({
                    account_id: contact.account_id,
                    contact_id: contact.contact_id,

                    custom_data,
                    pipeline_stage_id: Number(stage),
                    master_source,
                    campaign_id: campaign_converted,
                    secondary_source_id: secondary_source,

                    previous_sequence_complete: areSequenceTasksCompleted,
                    sequence_conversion_removed: canApplyNewSequence
                }))[0]));
                if (canApplyNewSequence && newStage?.sequence_id) {
                    await dispatch(sequencesActions.addToSequence({ contact_id }, null, newStage?.sequence_id));
                }
                return contact_id;
            })();
            Notification.alert('Successfully created lead!', STATUS_TYPES.SUCCESS);
            navigate(`/`);
            navigate(`/contact/${idOfContactWithNewModule}`, { replace: true });
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create lead', STATUS_TYPES.ERROR);
        }
    }
    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])
    const hasErrors = Object.keys(errors).length > 0;
    return (
        [
            <Modal.Content scrolling>
                <Form noValidate>
                    <Header as='h3' color='primary'>Contact Info</Header>
                    <List divided verticalAlign='middle'>
                        {contactsToConvert.map((contact, index) => (
                            <List.Item>
                                <Form.Field
                                    control={'div'}
                                    label='Contact Name'
                                    key={contact.id}
                                    required
                                >
                                    <ContactOptions
                                        contact={contact}
                                        control={control}
                                        index={index}
                                        checkIfContactCanBeSelected={(contact) => { return contact.current_stage === undefined; }}
                                        isOptionAlreadySelected={() => { return false; }}
                                        onSelectContact={onSelectContact}
                                        hasPrimaryToggle={false}
                                        clearErrors={clearErrors}
                                        name='contactsToConvert'
                                        setError={setError}
                                        trigger={trigger}
                                    />
                                </Form.Field>
                            </List.Item>
                        ))}
                    </List>
                    {(watchedContacts[0].new || watchedContacts[0].account_id === undefined) && [
                        <Header as='h3' color='primary'>Account</Header>,
                        <Controller
                            name='account'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Field
                                    name={name}
                                    value={value}
                                    control={AccountOptions}
                                    label='Link to Existing Account'
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                />
                            )}
                        />
                    ]}
                    <Header as='h3' color='primary'>Lead Info</Header>
                    <Form.Group widths='equal'>
                        <Controller
                            name='pipeline'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Controller
                                    control={control}
                                    name='stage'
                                    render={({ field: { name: stageName, onChange: onStageChange } }) => (
                                        <Form.Select
                                            label='Pipeline'
                                            required
                                            name={name}
                                            value={value}
                                            onChange={(_, { name, value }) => {
                                                onChange({ target: { name, value } });
                                                onStageChange({ target: { name: stageName, value: pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null } });
                                            }}
                                            options={pipelineCategories}
                                            search
                                            selectOnBlur={false}
                                            placeholder='Select Pipeline'
                                            loading={isReadingCategories}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name='stage'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    label='Stage'
                                    required
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    options={pipelineCategories.find(category => category.value === watchedPipeline)?.stages || []}
                                    search
                                    selectOnBlur={false}
                                    placeholder='Select Stage'
                                />
                            )}
                        />
                        <Controller
                            name='type'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    label='Lead Type'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    options={CustomGroupUtils.getFieldOptions(fields, name)}
                                    search
                                    selectOnBlur={false}
                                    placeholder='Select Type'
                                    clearable
                                    loading={isReadingCustomFields}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <Controller
                            name='product_interests'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    label='Product Interests'
                                    multiple
                                    search
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    options={[]}
                                    selectOnBlur={false}
                                    placeholder='Select Product Interest(s)'
                                />
                            )}
                        />
                        <Controller
                            name='expected_revenue'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    label='Expected Revenue'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter Expected Revenue'
                                />
                            )}
                        />
                        <Controller
                            name='close_probability'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    label='Close Probability'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter Close Probability'
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <Controller
                            name='master_source'
                            control={control}
                            render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                                <Controller
                                    name='secondary_source'
                                    control={control}
                                    render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                                        <Form.Field
                                            control={MasterSecondarySourceOptions}
                                            onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                                masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                                secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                            }}
                                            value={secondarySourceValue}
                                            parentValue={masterSourceFieldValue}
                                            label='Master/Secondary Source'
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name='priority'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    label='Priority'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    options={CustomGroupUtils.getFieldOptions(fields, name).map(option => ({
                                        ...option,
                                        content: (
                                            <> <PriorityIcon type={option.text?.toLowerCase()} /> {option.text} </>
                                        )
                                    }))}
                                    search
                                    selectOnBlur={false}
                                    placeholder='Select Lead Priority'
                                    clearable
                                    loading={isReadingCustomFields}
                                />
                            )}
                        />
                        <Controller
                            name='rating'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    label='Rating'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    options={CustomGroupUtils.getFieldOptions(fields, name).map(option => ({
                                        ...option,
                                        content: (
                                            <> <TemperatureIcon type={option.text?.toLowerCase()} /> {option.text} </>
                                        )
                                    }))}
                                    search
                                    selectOnBlur={false}
                                    placeholder='Select Lead Rating'
                                    clearable
                                    loading={isReadingCustomFields}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <Controller
                            name='score'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    label='Score'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter Lead Score'
                                />
                            )}
                        />
                        <Controller
                            name='acquisition_cost'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    label='Acquisition Cost'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter Acquisition Cost'
                                />
                            )}
                        />
                        <Controller
                            name='additional_cost'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    label='Additional Costs'
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter Additional Costs'
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group widths={"equal"}>
                        <Controller
                            name={'campaign_converted'}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    fluid
                                    label='Campaign Converted'
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    options={campaignConvertedOptions.map((campaign) => ({ key: campaign.id, value: campaign.id, text: campaign.name }))}
                                    search
                                    selectOnBlur={false}
                                    disabled={!watchedContacts[0].contact_id}
                                    placeholder='Select Campaign'
                                />
                            )}
                        />
                        <Form.Field />
                        <Form.Field />
                    </Form.Group>
                    <Header as='h3' color='primary'>Custom Data</Header>
                    <CustomData
                        control={control}
                        isEditing
                        padded
                    />
                </Form>
            </Modal.Content>,
            <Modal.Actions>
                <Button disabled={!isDirty || !isValid || hasErrors || isCreating} primary onClick={handleSubmit(onCreate)} loading={isCreating}>Create</Button>
            </Modal.Actions>
        ]
    )
}

const AddLeadModal = ({
    onClose,
    onDirtyChange
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getContactStandardGroup({ group_name: 'person' }));
        dispatch(customFieldsGroupActions.getModalStandardGroup({ group_name: 'lead' }));
        dispatch(pipelineActions.getPipelineCategories({ account_stage: "lead" }));
    }, [dispatch]);

    const isReadingCustomFields = useSelector(state => state.custom_fields_group.isReadingContactStandardFieldGroup || state.custom_fields_group.isReadingModalStandardFieldGroup);

    return (
        <React.Fragment>
            <Modal.Header>
                Add New
            </Modal.Header>
            {isReadingCustomFields ? (
                <Segment loading basic></Segment>
            ) : (
                <ModalContent
                    onClose={onClose}
                    onDirtyChange={onDirtyChange}
                />
            )}
        </React.Fragment>
    )
}

export default AddLeadModal;
