import { Form } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { Button } from "dyl-components";

const ProductCategoryForm = ({ onSave, isSaving, onDirtyChange }) => {
    const { control, formState: { isDirty, isValid }, handleSubmit } = useFormContext();


    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])

    return (
        <Form noValidate style={{ width: "30em" }} loading={isSaving}>
            <Controller
                name="name"
                control={control}
                render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } });
                        }}
                        error={error?.message}
                        label={`Category Name`}
                        placeholder={`Enter category name`}
                        required
                    />
                )}
            />
            <Controller
                label="Product Description"
                control={control}
                name="description"
                render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                }) => (
                    <Form.TextArea
                        label="Category Description"
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({
                                target: { name, value },
                            });
                        }}
                        error={error?.message}
                    />
                )}
            />
            <Button floated='right' disabled={!isDirty || !isValid || isSaving} primary onClick={handleSubmit(onSave)} loading={isSaving}>Save</Button>
        </Form>
    );
};

export default ProductCategoryForm;
