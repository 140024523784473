import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Segment, Form, Loader, Portal } from 'semantic-ui-react';
import { STATUS_TYPES, Notification, generateResolver, VALIDATORS, yup, DateTimeUtils, Button } from 'dyl-components';
import { StringUtils } from 'utils';

import Logo from './Logo';
import ProfileContactInfo from './ProfileContactInfo';
import Signature from './Signature';

import companyActions from 'actions/company';
import { Controller, useForm } from 'react-hook-form';
import { STATES } from 'shared/constants/STATES';
import SettingsFooter from 'dyl-components/molecules/SettingsFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import { Link } from 'react-router-dom';

const TIMEZONE_OPTIONS = DateTimeUtils.generateTimezoneOptions();

const CompanyProfile = ({
    onUpdateCompany,
    onAddCompanySocialMedias,
    onUpdateCompanySocialMedia,
    onDeleteCompanySocialMedia,
    onAddCompanyPhones,
    onUpdateCompanyPhone,
    onAddCompanyLocations,
    onUpdateCompanyLocation,
    onUpdateSignature,
    onAddSignature,
    onUpdateSignaturePreferences,
    onDeleteCompanyLogo,
    onUploadCompanyLogo,
    industry_options,

    signature_body_copy,
    trial,
    location_id,
    primary_phone_label,
    primary_phone_id,
    mobile_phone_label,
    mobile_phone_ext,
    mobile_phone_id,
    fax_number_label,
    fax_number_ext,
    fax_number_id,
    location_label,
    signature_type,
    signature_label,
    signature_id,

    name,
    website,
    industry,
    timezone,
    customer_id,
    email,
    fiscal_year_start,
    ein,
    street,
    additional_street,
    city,
    state,
    zip,

    primary_number,
    ext,

    mobile_number,
    fax_number,

    facebook,
    linkedin,
    twitter,
    google,

    new_email,
    reply_forward_email,
    signature_body,

    small_logo,
    large_logo,
    logo,
    onRead
}) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const { isConfirmed } = useConfirm();
    const { formState: { isValid, isDirty }, control, handleSubmit, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            small_logo,
            large_logo,
            logo: logo,

            name,
            website,
            industry,
            timezone,
            email,
            fiscal_year_start,
            ein,
            street,
            additional_street,
            city,
            state,
            zip,

            primary_number,
            ext,
            mobile_number,
            fax_number,

            facebook: facebook.url,
            linkedin: linkedin.url,
            twitter: twitter.url,
            google: google.url,

            new_email,
            reply_forward_email,
            signature_body
        },
        resolver: generateResolver({
            name: VALIDATORS.BUSINESS_NAME().no_whitespace_only().required('This field is required'),
            timezone: yup.string().oneOf(TIMEZONE_OPTIONS.map(option => option.value)).required('This field is required'),
            email: VALIDATORS.EMAIL_ADDRESS().maxlength(256),
            state: yup.string().oneOf(STATES.map(({ key }) => key)),
            website: VALIDATORS.WEBSITE().no_whitespace_only().maxlength(256).minlength(2),

            primary_number: VALIDATORS.PHONE_NUMBER(),
            mobile_number: VALIDATORS.PHONE_NUMBER(),
            fax_number: VALIDATORS.PHONE_NUMBER(),

            new_email: yup.number().oneOf([0, 1]),
            reply_forward_email: yup.number().oneOf([0, 1]),
            street: yup.string().maxlength(100),
            additional_street: yup.string().maxlength(12),
            city: yup.string().maxlength(60),
            ext: yup.string().maxlength(11),
            zip: VALIDATORS.US_POSTAL_CODE().simple_alphanumeric(),

            facebook: VALIDATORS.WEBSITE().no_whitespace_only().minlength(2).maxlength(256),
            linkedin: VALIDATORS.WEBSITE().no_whitespace_only().minlength(2).maxlength(256),
            twitter: VALIDATORS.WEBSITE().no_whitespace_only().minlength(2).maxlength(256),
            google: VALIDATORS.WEBSITE().no_whitespace_only().minlength(2).maxlength(256),
        })
    });

    const [isSaving, setIsSaving] = useState(false);

    const onUpdate = async (data) => {
        try {
            setIsSaving(true);
            const { 
                name, 
                website, 
                email, 
                timezone, 
                fiscal_year_start, 
                ein, industry, 
                logo, 
                small_logo, 
                large_logo, 
                facebook: new_facebook, 
                linkedin: new_linkedin, 
                twitter: new_twitter, 
                google: new_google,

                primary_number,
                ext,
                mobile_number,
                fax_number,

                signature_body,
                new_email,
                reply_forward_email,

                additional_street,
                street,
                city,
                state,
                zip
            } = data;
            await Promise.all([
                onUpdateCompany({
                    name,
                    website,
                    email,
                    timezone,
                    fiscal_year_start,
                    ein,
                    industry,
                    small_logo,
                    large_logo,
                    trial
                }),
                modifyLocation(additional_street, city, state, street, zip),
                modifyPhone(primary_number, ext, true, primary_phone_label, 'work', primary_phone_id),
                modifyPhone(mobile_number, mobile_phone_ext, false, mobile_phone_label, 'cell', mobile_phone_id),
                modifyPhone(fax_number, fax_number_ext, false, fax_number_label, 'work_fax', fax_number_id),
                addCompanySocialMedia(new_facebook, new_linkedin, new_twitter, new_google),
                updateCompanySocialMedia(new_facebook, facebook),
                updateCompanySocialMedia(new_linkedin, linkedin),
                updateCompanySocialMedia(new_twitter, twitter),
                updateCompanySocialMedia(new_google, google),
                modifySignaturesAndPreferences(signature_body, reply_forward_email, new_email),
                uploadCompanyLogo(logo)
            ])
            Notification.alert("Successfully updated company information!", STATUS_TYPES.SUCCESS, true);

            await onRead();
            setUploadedNewLogo(false);
            setIsSaving(false);
        } catch (e) {
            console.log(e);
            Notification.alert("There was a problem in updating company information. Try resubmitting later.", STATUS_TYPES.ERROR, true);
            setIsSaving(false);
        }
    }

    const addCompanySocialMedia = (new_facebook, new_linkedin, new_twitter, new_google) => {
        const social_medias = [];
        if (facebook.id === null && new_facebook) {
            social_medias.push({ type: 'facebook', url: StringUtils.formatLink(new_facebook) });
        }
        if (linkedin.id === null && new_linkedin) {
            social_medias.push({ type: 'linkedin', url: StringUtils.formatLink(new_linkedin) });
        }
        if (twitter.id === null && new_twitter) {
            social_medias.push({ type: 'twitter', url: StringUtils.formatLink(new_twitter) });
        }

        if (google.id === null && new_google) {
            social_medias.push({ type: 'google', url: StringUtils.formatLink(new_google) });
        }

        if (social_medias.length > 0) {
            return onAddCompanySocialMedias({ social_medias });
        }
        return Promise.resolve();
    }

    const updateCompanySocialMedia = (new_url, social) => {
        if (social.id) {
            if (new_url?.trim()) {
                return onUpdateCompanySocialMedia(social.id, { type: social.type, url: StringUtils.formatLink(new_url) })
            }
            return onDeleteCompanySocialMedia(social.id);
        }
        return Promise.resolve();
    }

    const modifyPhone = (number, phone_ext, main, label, type, id) => {
        const phone = {
            label,
            phone: number,
            phone_ext: phone_ext,
            main,
            phone_type: type
        }

        if (id) {
            return onUpdateCompanyPhone(id, phone);
        }

        return onAddCompanyPhones({
            phones: [{
                ...phone
            }]
        });
    }

    const modifyLocation = (new_additional_street, new_city, new_state, new_street, new_zip) => {
        const location = {
            additional_street: new_additional_street,
            city: new_city,
            label: location_label,
            main: true,
            state: new_state,
            street: new_street,
            zip: new_zip
        };

        if (location_id) {
            return onUpdateCompanyLocation(location_id, location);
        }
        return onAddCompanyLocations({
            locations: [{
                ...location, label: "Company Location"
            }]
        });
    }

    const modifySignaturesAndPreferences = (new_signature_body, reply_forward_email, new_email) => {
        const signature = {
            content: new_signature_body,
            content_type: signature_type,
            label: signature_label,
            main: true
        };
        if (signature_id) {
            return Promise.all([
                onUpdateSignature(signature_id, signature),
                onUpdateSignaturePreferences({
                    new_email: new_email ? signature_id : null,
                    reply_forward_email: reply_forward_email ? signature_id : null
                })
            ]);
        }
        return onAddSignature(signature).then(({ id }) => {
            return onUpdateSignaturePreferences({
                new_email: new_email ? id : null,
                reply_forward_email: reply_forward_email ? id : null
            })
        });
    }

    const uploadCompanyLogo = (new_logo) => {
        if (new_logo === null && logo !== null) {
            return onDeleteCompanyLogo();
        }
        if (new_logo !== logo) {
            return onUploadCompanyLogo(new_logo);
        }
        return Promise.resolve();
    }

    const [uploadedNewLogo, setUploadedNewLogo] = useState(false);
    const [focusedPhones, setFocusedPhones] = useState([]);
    const addFocus = (type) => {
        setFocusedPhones([...focusedPhones, type]);
    };
    const removeFocus = (type) => {
        setFocusedPhones(focusedPhones.filter(phone => phone !== type));
    }
    const isFocused = (type) => {
        return focusedPhones.includes(type);
    }

    useEffect(() => {
        setValue("logo", null);
        const img = new Image();
        img.src = logo
        img.onload = () => {
            setValue("logo",logo);
        }
        img.onerror = () => {
            setValue("logo", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logo])
    
    CustomPrompt(null, isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');
    return (
        <>
        <ConfirmModal />
            <Segment className='CompanyProfile__container' basic as={Grid} loading={isSaving}>
                <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column style={{ paddingLeft: 0 }}>
                        <Form size='small'>
                            <Controller
                                name='logo'
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Logo
                                        logo={value}
                                        uploadedNewLogo={uploadedNewLogo}
                                        onUploadLogo={(value) => {
                                            onChange({ target: { name, value } });
                                            setUploadedNewLogo(true);
                                        }}
                                        onRemoveLogo={() => {
                                            onChange({ target: { name, value: null } });
                                            setUploadedNewLogo(false);
                                        }}
                                    />
                                )}
                            />
                            <ProfileContactInfo
                                control={control}
                                industry_options={industry_options}
                                addFocus={addFocus}
                                removeFocus={removeFocus}
                                isFocused={isFocused}
                                customer_id={customer_id}
                            />
                            <Signature
                                control={control}
                                signature_body_copy={signature_body_copy}
                            />
                            
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Segment>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button as={Link} to='/settings/account-management' status={'cancel'} basic>Cancel</Button>,
                        <Button onClick={handleSubmit(onUpdate)} disabled={!isValid || !isDirty || isSaving} loading={isSaving} primary>
                            Save
                        </Button>
                    ]}
                />
            </Portal>
        </>
    );
};


const CompanyProfileContainer = (props) => {
    const onRead = useCallback(() => {
        return Promise.all([
            props.onReadCompanyLogo(),
            props.onReadCompany(),
            props.onReadSignatures(),
            props.onReadSignaturePreferences(),
            props.onReadIndustry()
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        onRead();
    }, [onRead])

    
    if (props.isReading) {
        return <Loader active />
    }
    return (
        <CompanyProfile
            {...props}
            onRead={onRead}
        />
    );
};

const mapStateToProps = state => {
    const { company } = state;

    const { industry: industries, company: companyProfile, signatures, signature_preferences, isReadingCompany, isUpdatingCompany,
        isUpdatingCompanyLocation, isUpdatingCompanyPhone, isUpdatingCompanySocialMedia, isReadingSignatures,
        signatureBeingUpdated, isReadingSignaturePreferences, isUpdatingSignaturePreferences, logo, isReadingCompanyLogo,
        isUploadingCompanyLogo, isDeletingCompanyLogo, isDeletingCompanySocialMedia } = company;

    const { name, industry, website, email, fiscal_year_start, timezone, ein, locations, social_medias,
        phones, small_logo, large_logo, trial, customer_id } = companyProfile;

    const industry_options = industries ? industries.map(({ id, name }) => ({ key: id, value: id, text: name })) : [];
   
    let location = {};

    if (locations && locations.length > 0) {
        location = locations.filter(location => location.main).length > 0 ? locations.filter(location => location.main)[0] : locations[0];
    }

    let primary_phone = {};
    let mobile_phone = {};
    let fax_phone = {};

    if (phones && phones.length > 0) {
        primary_phone = phones.filter(phone => phone.phone_type === 'work' && phone.main).length > 0 ? phones.filter(phone => phone.phone_type === 'work' && phone.main)[0] : { phone: '', phone_ext: '' };
        mobile_phone = phones.filter(phone => phone.phone_type === 'cell' && !phone.main).length > 0 ? phones.filter(phone => phone.phone_type === 'cell' && !phone.main)[0] : { phone: '' };
        fax_phone = phones.filter(phone => phone.phone_type === 'work_fax' && !phone.main).length > 0 ? phones.filter(phone => phone.phone_type === 'work_fax' && !phone.main)[0] : { phone: '' };
    }

    let signature = {};

    if (signatures && signatures.length > 0) {
        signature = signatures.filter(signature => signature.main).length > 0 ? signatures.filter(signature => signature.main)[0] : signatures[0];
    }

    const { new_email, reply_forward_email } = signature_preferences;

    const { street = '', additional_street = '', city = '', state: address_state = '', zip = '', label: location_label = 'Company Location', id: location_id } = location;
    const { id: primary_phone_id, phone: primary_number = '', phone_ext: ext = '', label: primary_phone_label = 'Company Primary Phone' } = primary_phone;
    const { id: mobile_phone_id, phone: mobile_number = '', phone_ext: mobile_phone_ext = '', label: mobile_phone_label = 'Company Mobile Phone' } = mobile_phone;
    const { id: fax_number_id, phone: fax_number = '', phone_ext: fax_number_ext = '', label: fax_number_label = 'Company Fax Number' } = fax_phone;
    const { id: signature_id, content: signature_body = '', content_type = 'html', label: signature_label = 'Company Signature' } = signature;

    return {
        customer_id,

        name: name || '',
        website,
        email,
        timezone,
        fiscal_year_start,
        ein,
        small_logo,
        large_logo,
        trial,

        industry: industry ? industry.id : 0,

        location_id,
        location_label,
        street,
        additional_street,
        city,
        state: address_state,
        zip,


        primary_phone_id,
        primary_number,
        primary_phone_label,
        ext,

        mobile_phone_id,
        mobile_number,
        mobile_phone_label,
        mobile_phone_ext,

        fax_number_id,
        fax_number,
        fax_number_label,
        fax_number_ext,

        facebook: social_medias ? (social_medias.find(site => site.type.toLowerCase() === 'facebook') || { id: null, url: '', type: 'facebook' }) : { url: '', id: null, type: 'facebook' },
        linkedin: social_medias ? (social_medias.find(site => site.type.toLowerCase() === 'linkedin') || { id: null, url: '', type: 'linkedin' }) : { id: null, url: '', type: 'linkedin' },
        twitter: social_medias ? (social_medias.find(site => site.type.toLowerCase() === 'twitter') || { id: null, url: '', type: 'twitter' }) : { id: null, url: '', type: 'twitter' },
        google: social_medias ? (social_medias.find(site => site.type.toLowerCase() === 'google') || { id: null, url: '', type: 'google' }) : { id: null, url: '', type: 'google' },

        industry_options,

        isReading: isReadingCompany || isReadingSignatures || isReadingCompanyLogo,
        isUpdating: isUpdatingCompany || isUpdatingCompanyLocation || isUpdatingCompanyPhone
            || isUpdatingCompanySocialMedia || signatureBeingUpdated || isUploadingCompanyLogo || isDeletingCompanyLogo
            || isReadingSignaturePreferences || isUpdatingSignaturePreferences || isDeletingCompanySocialMedia,

        logo: logo ? logo : null,

        signature_id,
        signature_body,
        content_type,
        signature_label,

        new_email: new_email ? 1 : 0,
        reply_forward_email: reply_forward_email ? 1 : 0
    }
};
const mapDispatchToProps = dispatch => ({
    onReadCompany: () => {
        return dispatch(companyActions.readCompany())
    },
    onUpdateCompany: (payload) => {
        return dispatch(companyActions.updateCompany('', payload));
    },
    onReadIndustry: () => {
        return dispatch(companyActions.readIndustry());
    },
    onReadSignatures: () => {
        return dispatch(companyActions.readSignatures());
    },
    onAddSignature: (payload) => {
        return dispatch(companyActions.createSignature(payload));
    },
    onUpdateSignature: (signature_id, payload) => {
        return dispatch(companyActions.updateSignature(signature_id, payload));
    },

    onReadSignaturePreferences: () => {
        return dispatch(companyActions.readSignaturePreferences());
    },
    onUpdateSignaturePreferences: (payload) => {
        return dispatch(companyActions.updateSignaturePreferences('', payload));
    },

    onAddCompanyLocations: (payload) => {
        return dispatch(companyActions.createCompanyLocations(payload));
    },
    onUpdateCompanyLocation: (location_id, payload) => {
        return dispatch(companyActions.updateCompanyLocation(location_id, payload))
    },

    onAddCompanySocialMedias: (payload) => {
        return dispatch(companyActions.createCompanySocialMedias(payload))
    },
    onUpdateCompanySocialMedia: (social_media_id, payload) => {
        return dispatch(companyActions.updateCompanySocialMedia(social_media_id, payload));
    },
    onDeleteCompanySocialMedia: (social_media_id) => {
        return dispatch(companyActions.deleteCompanySocialMedia(social_media_id))
    },

    onAddCompanyPhones: (payload) => {
        return dispatch(companyActions.createCompanyPhones(payload))
    },
    onUpdateCompanyPhone: (phone_id, payload) => {
        return dispatch(companyActions.updateCompanyPhone(phone_id, payload));
    },

    onReadCompanyLogo: () => {
        return dispatch(companyActions.readCompanyLogo({ thumbnail: true }));
    },
    onUploadCompanyLogo: (file) => {
        return dispatch(companyActions.uploadCompanyLogo(file));
    },
    onDeleteCompanyLogo: () => {
        return dispatch(companyActions.deleteCompanyLogo());
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileContainer);
