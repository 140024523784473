import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, ModulesFilter } from 'dyl-components';

import {
    KEY_CREATED_ON, KEY_NAME, KEY_REACHED,
    KEY_CREATED_BY, KEY_TYPE, KEY_UNREACHED,
    KEY_LAST_MODIFIED_ON, KEY_CATEGORY_SUBCATEGORY, KEY_OWNER,
    KEY_LAST_MODIFIED_BY, KEY_STATUS, KEY_ACTIONS,
    KEY_START_END_DATE, KEY_SHARED,
    KEY_ID, KEY_TOTAL_CONTACTS, KEY_CAMPAIGN_CONVERSION
} from './constants';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED_ON, label: "Created On" },
        { value: KEY_NAME, label: "Name", isForcedChecked: true },
        { value: KEY_TOTAL_CONTACTS, label: "Total Contacts" }
    ],
    [
        { value: KEY_CREATED_BY, label: "Created By" },
        { value: KEY_TYPE, label: "Type" },
        { value: KEY_REACHED, label: "Reached" }
    ],
    [
        { value: KEY_LAST_MODIFIED_ON, label: "Last Modified On" },
        { value: KEY_CATEGORY_SUBCATEGORY, label: "Category/Subcategory" },
        { value: KEY_UNREACHED, label: "Unreached" }
        
    ],
    [
        { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
        { value: KEY_CAMPAIGN_CONVERSION, label: "Campaign Conversion" },
        
        { value: KEY_OWNER, label: "Owner" }
        
    ],
    [
        { value: KEY_START_END_DATE, label: "Start/End date" },
        { value: KEY_STATUS, label: "Status" },
        { value: KEY_ACTIONS, label: "Actions" }
    ],
    [
        { value: KEY_ID, label: "ID", isForcedChecked: true },
        { value: KEY_SHARED, label: "Shared" }
    ]
];

const CampaignToolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, isColumnHidden, onToggleColumn }) => {
    const navigate = useNavigate();

    const { isReadingCampaigns } = useSelector(state => ({
        isReadingCampaigns: state.campaigns.isReadingCampaigns
    }));

    return (
        <Grid>
            <Grid.Row className='CampaignsToolbar'>
                <Grid.Column width={5}>
                    <ModulesFilter
                        isLoading={isReadingCampaigns}
                        search={search}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        onChangeSearch={onChangeSearch}
                        columnRows={COLUMN_ROWS}
                        columnCount={3}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                        searchBarPlaceholder='Search by Campaign Name'
                    />
                </Grid.Column>
                <Grid.Column width={3} floated='right'>
                    <Button color='primary' floated='right' onClick={() => { navigate('/campaigns/create') }}>
                        <Icon className='plus' /> New Campaign
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default CampaignToolbar;
