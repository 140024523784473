import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PaymentInfo from 'shared/PaymentInfo';
import paymentActions from "actions/payment";
import { Icon, Notification, RecordsTable, STATUS_TYPES, Table } from 'dyl-components';
import { Grid, Header, Popup } from 'semantic-ui-react';
import PaymentMethodsTableRows from './PaymentMethodsTableRows';

export default function PaymentMethods() {
    const dispatch = useDispatch();
    const customer_id = useSelector(state => state.auth.customer_id);

    const { paymentMethods, isReadingPaymentMethods, isChangingPrimaryPaymentMethod, isDeletingPrimaryPaymentMethod } = useSelector((state) => ({
        paymentMethods:
            state.payment.profile?.paymentProfiles?.sort(
                (a, b) => (b.defaultPaymentProfile === true ? 1 : 0) - (a.defaultPaymentProfile === true ? 1 : 0)
            ).map(
                ({ customerPaymentProfileId, payment: paymentInfo, defaultPaymentProfile }) => ({
                    key: customerPaymentProfileId,
                    paymentInfo,
                    content: (
                        <PaymentInfo
                            info={{
                                payment_type: paymentInfo.creditCard ? "credit_card" : "eCheck",
                                payment_account: (paymentInfo.creditCard?.cardNumber || paymentInfo.bankAccount?.accountNumber),
                                payment_network: paymentInfo.creditCard?.cardType,
                                is_primary: defaultPaymentProfile
                            }}
                        />
                    ),
                    isPrimary: defaultPaymentProfile
                })
            ) || [],
        isReadingPaymentMethods: state.payment.isReadingCustomerProfile,
        isChangingPrimaryPaymentMethod: state.payment.isChangingPrimaryPaymentMethod,
        isDeletingPrimaryPaymentMethod: state.payment.isDeletingPrimaryPaymentMethod
    }));

    const setAsPrimary = async (customerPaymentProfileId) => {
        try {
            const paymentMethod = paymentMethods.find(paymentMethod => customerPaymentProfileId === paymentMethod.key);
            const { creditCard, bankAccount } = paymentMethod.paymentInfo;
            const paymentInfo = (() => {
                if (creditCard) {
                    const { cardType, issuerNumber, ...otherInfo } = creditCard;
                    return {
                        creditCard: otherInfo
                    };
                }
                return {
                    bankAccount
                };
            })();
            await dispatch(paymentActions.setPrimaryPaymentMethod(customer_id, {
                customerPaymentProfileId,
                paymentInfo
            }, { isAdmin: true }));
            Notification.alert('Successfully changed primary payment method!', STATUS_TYPES.SUCCESS);
            dispatch(paymentActions.getProfiles(customer_id, { isAdmin: true }));
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to change primary payment method', STATUS_TYPES.ERROR);
        }
    }

    const onDeletePaymentMethod = async (customerPaymentProfileId) => {
        try {
            // TODO: create this action in actions/payment
            await dispatch(paymentActions.deletePaymentMethod(customer_id, { isAdmin: true, customerPaymentProfileId }));
            Notification.alert('Successfully deleted payment method!', STATUS_TYPES.SUCCESS);
            dispatch(paymentActions.getProfiles(customer_id, { isAdmin: true }));
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete payment method', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        dispatch(paymentActions.getProfiles(customer_id, { isAdmin: true }))
    }, [dispatch, customer_id]);

    return (
        <Grid>
            <Grid.Row >
                <Grid.Column >
                    <Header as='h2' color='primary'>
                        Payment Method
                        <Popup
                            trigger={<Icon size='small' className='fas fa-circle-info' color='primary' style={{ marginLeft: 10, marginBottom: 5 }} />}
                            content={<>
                                One valid payment method is required on Account.
                            </>}
                            inverted
                            flowing
                            position='top center'
                        />
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    <RecordsTable
                        className
                        isTableLoading={isReadingPaymentMethods || isChangingPrimaryPaymentMethod || isDeletingPrimaryPaymentMethod}
                        isTableEmpty={paymentMethods.length === 0}
                        noBorder
                        TableHeader={
                            <Table.Row>
                                <Table.HeaderCell width={13}>Payment Method</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                            </Table.Row>
                        }
                        TableRows={<PaymentMethodsTableRows onDeletePaymentMethod={onDeletePaymentMethod} paymentMethods={paymentMethods} setAsPrimary={setAsPrimary} />}
                        hasPagination={false}
                        recordsName="Payment Method"
                        emptySubtitle=""
                        emptyListTitle="No Payment Methods Added"
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
