import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {SearchBar, Button} from 'dyl-components';

const TeamOptions = ({ isReadingTeams }) => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const search = query.get('search') || '';

    const encodedValue = value => encodeURIComponent(value);
    const searchFunction = (value)=> navigate(`/settings/teams?search=${encodedValue(value)}`);
    const cancelFunction = ()=> navigate(`/settings/teams`);
    
    return (
        <div className='TeamOptions'>
            <SearchBar 
                isSearching={isReadingTeams}
                search={search}
                searchFcn={searchFunction}
                cancelFcn={cancelFunction}
                placeholder={ 'Search for a team name'}
            /> 
            <Button as={Link} to='/settings/add-team' primary><Icon name='plus' /> New Team</Button>
        </div>
    );
}

const mapStateToProps = state => ({
    isReadingTeams: state.teams.isReadingTeams
});

export default connect(mapStateToProps, null)(TeamOptions);
