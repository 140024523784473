import { ControlledPopup, EllipsisDropdown, Table } from 'dyl-components';
import React from 'react'
import { Dropdown, Header } from 'semantic-ui-react';
import PaymentInfo from 'shared/PaymentInfo';

export default function PaymentMethodsTableRows({paymentMethods, setAsPrimary, onDeletePaymentMethod }) {
  return paymentMethods.map((paymentMethod) => {
    const { key, paymentInfo, isPrimary } = paymentMethod;
    const accountNumber = paymentInfo.creditCard?.cardNumber || paymentInfo.bankAccount?.accountNumber;
    const cardType = paymentInfo.creditCard?.cardType;

    return (
        <Table.Row key={key}>
            <Table.Cell>
                <PaymentInfo
                    info={{
                        payment_type: paymentInfo.creditCard ? "credit_card" : "eCheck",
                        payment_account: accountNumber,
                        payment_network: cardType,
                        is_primary: isPrimary
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    {[
                        {
                            id: 1,
                            text: 'Set as Primary',
                            icon: 'fas fa-circle-dollar',
                            onConfirm: () => setAsPrimary(key),
                            disabled: isPrimary,
                            displaysPopup: true,
                            popupProps: {
                                header: 'Are you sure?',
                                content: "Payment method will be set as primary."
                            },
                        },
                        {
                            id: 2,
                            icon: 'fas fa-trash-alt',
                            text: 'Delete',
                            displaysPopup: true,
                            onConfirm: () => onDeletePaymentMethod(key),
                            popupProps: {
                                header: 'Are you sure?',
                                content:"You cannot undo delete actions."
                            },
                            disabled: isPrimary || paymentMethods.length === 1,
                        },
                    ].map(({ text, icon, displaysPopup, popupProps, onConfirm, ...otherProps }, idx) => {
                        if (displaysPopup && popupProps) {
                            return (
                                <ControlledPopup
                                    key={idx}
                                    header={<Header as='h4' textAlign='center'>
                                        {popupProps.header}
                                        <Header.Subheader style={{ marginTop: 5 }}>
                                            {popupProps.content}
                                        </Header.Subheader>
                                    </Header>}
                                    trigger={<Dropdown.Item disabled={otherProps.disabled} icon={icon} text={text} />}
                                    onConfirm={onConfirm}
                                    position="bottom right"

                                />
                            );
                        }
                        return (
                            <Dropdown.Item
                                key={idx}
                                {...otherProps}
                                text={text}
                                icon={icon}
                            />
                        );
                    })}
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
})
}
